<template>
  <v-dialog scrollable :value="true" @click:outside="close()" max-width="500">
    <v-card>
      <v-toolbar color="success" dark class="mb-4"
        ><v-toolbar-title>{{ details.subject.description }}</v-toolbar-title>

        <v-spacer />
        <v-icon @click="close()">mdi-close</v-icon>
        <template v-slot:extension>
          <v-tabs
            v-model="tab"
            slider-color="white"
            background-color="success darken-1"
            fixed-tabs
          >
            <v-tab
              v-model="tab"
              v-for="(students, key) in itemsGrouped"
              :key="'tab' + key"
            >
              {{ key }} ({{ students.length }})
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-card-text class="pa-0 ma-0">
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(schoolClass, key) in itemsGroupedGrouped"
            :key="'schoolClass' + key"
          >
            <v-list
              subheader
              v-for="(students, key) in schoolClass"
              :key="'item' + key"
            >
              <v-subheader>{{ key }}</v-subheader>
              <PersonItem
                v-for="student in students"
                :key="student.id"
                :value="student"
              />
            </v-list>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-divider />
      <v-card-actions
        ><v-spacer /><v-btn @click="close()" text
          >schliessen</v-btn
        ></v-card-actions
      ></v-card
    ></v-dialog
  >
</template>
<script>
import PersonItem from '@/components/PersonItem.vue';
import { groupBy } from 'lodash';

export default {
  props: ['subject'],
  components: { PersonItem },
  data() {
    return {
      details: { subject: '', students: [] },
      tab: 0,
    };
  },
  watch: {
    subject() {
      this.fetchData();
    },
  },
  computed: {
    itemsGroupedGrouped() {
      const result = {};
      for (const [key, value] of Object.entries(this.itemsGrouped)) {
        const grouped = groupBy(value, (el) => el.schoolClass.code);
        result[key] = Object.fromEntries(Object.entries(grouped).sort());
      }
      return result;
    },
    itemsGrouped() {
      const grouped = groupBy(this.details.students, (el) => {
        if (this.details.currentTerm.id % 2 == 0) {
          return el.grade.code + '/' + 'GYM' + (6 - el.grade.id);
        }
        return el.grade.code;
      });

      return Object.fromEntries(Object.entries(grouped).sort());
    },
  },
  methods: {
    async fetchData() {
      this.details = await this.apiList({
        resource: 'optional/statistics',
        query: 'subject=' + this.subject,
      });
    },
    close() {
      this.$router.push({ name: 'OptionalStatistics' });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
